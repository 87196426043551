import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import Button from '../../components/Button'

const AddSetList = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col p-3">
      <div className="flex items-center gap-2  mb-4">
        <AiOutlineArrowLeft onClick={handleGoBack} />
        <p className=" text-16 font-semibold">Add New SetList</p>
      </div>
      <div className="bg-main-bg-dark p-5  w-full lg:w-1/3 m-auto">
        <form
          // onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4"
        >
          <Input
            // value={formik.values.bandName}
            placeholder="Set List Name"
            name="setList"
            classes="bg-slate-400"
            // label="Set List"
            // formik={formik}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // error={formik.touched.bandName && formik.errors.bandName}
            // classes={formik.errors.bandName ? "border border-pink" : null}
          />
          <p className="text-white">
            After creating a set list, you can add songs from your library
          </p>
          <Button
            bgColor="bg-main-bg-pink"
            text="Add SetList"
            size="md"
            borderRadius="0px"
            // type="submit"
            color="white"
          />
          <Button
            bgColor="transparent"
            text="Cancel"
            size="md"
            borderRadius="0px"
            color="white"
            border="2px solid white"
            onClick={handleGoBack}
          />
        </form>
      </div>
    </div>
  )
}

export default AddSetList
