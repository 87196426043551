import React from 'react'
import PropTypes from 'prop-types'

const Button = ({
  bgColor,
  color,
  size,
  text,
  borderRadius,
  border,
  icon,
  type,
  onClick,
  classes,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{ color, borderRadius, border }}
      className={`text-${size} py-2 px-4 hover:drop-shadow-xl ${bgColor} ${classes} flex items-center justify-center gap-1 ${
        bgColor === 'transparent'
          ? 'hover:bg-main-dark-bg hover:text-white'
          : ''
      }`}
    >
      {icon && <span className="button-icon">{icon}</span>}
      {text}
    </button>
  )
}

Button.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  borderRadius: PropTypes.string,
  border: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.string,
}

export default Button
