import React from 'react'
import PropTypes from 'prop-types'
import { AiFillDelete } from 'react-icons/ai'
import { RiMusicFill } from 'react-icons/ri'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

SortableItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}
export default function SortableItem({ id, title }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex items-center justify-between p-2  text-white"
    >
      <div className="flex gap-2 items-center ">
        <RiMusicFill className="text-pink" />
        <p>{title}</p>
      </div>
      <div>
        <AiFillDelete />
      </div>
    </li>
  )
}
