import { useRegister } from '../useAuth'
import { useFormik } from 'formik'
import { registerSchema } from './loginValidationSchema'

const initialValues = {
  email: '',
  password: '',
  nickname: '',
  bandName: '',
  confirmPassword: '',
}

export const useRegisterForm = () => {
  // const navigate = useNavigate()
  const { mutate, isLoading } = useRegister({})

  const handleRegister = (values) => {
    const { email, password, nickname, bandName } = values
    mutate({ email, password, nickname, bandName })
  }

  const onSubmit = (values) => {
    handleRegister(values)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit,
  })
  const handleKeyDown = (event) => {
    // Trigger validation on keydown event
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }
  return {
    handleRegister,
    formik,
    isLoading,
    handleKeyDown,
  }
}
