import React from 'react'
import Input from './Input'
import Button from './Button'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { useLoginForm } from '../hooks/auth/components/useLogin'

import { useNavigate } from 'react-router-dom'

const Login = () => {
  const { formik } = useLoginForm()

  const navigate = useNavigate()
  const routeChange = () => {
    navigate(`/register`)
  }

  const forgotPasswordPage = () => {
    navigate('/forgot-password')
  }

  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return (
    <div className="flex flex-col text-left w-96 px-4 pt-6 pb-4 bg-main-bg-dark text-white">
      <p className="text-3xl mb-8">Login</p>
      <form
        onSubmit={(e) => handleForm(e)}
        className="flex flex-col text-left w-full gap-4 mb-4"
      >
        <div className="relative">
          <Input
            value={formik.values.email}
            placeholder="Enter Email"
            name="email"
            label="Email"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && formik.errors.username}
            classes={formik.errors.email ? 'border border-pink' : null}
          />
          {formik.errors.email ? (
            <div className="absolute right-0 top-auto text-pink">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className="relative">
          <Input
            value={formik.values.password}
            placeholder="Enter Password"
            name="password"
            label="Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            classes={formik.errors.password ? 'border border-pink' : null}
          />
          {formik.errors.password ? (
            <div className="absolute right-0 top-auto text-pink">
              {formik.errors.password}
            </div>
          ) : null}
        </div>
        {formik.errors.password ? <div>{formik.errors.password}</div> : null}
        {/* <div className="flex flex-row justify-between"> */}
        <Button
          bgColor="transparent"
          text="Forgot Password?"
          size="md"
          borderRadius="0px"
          color="white"
          icon={<AiFillQuestionCircle />}
          onClick={forgotPasswordPage}
        />
        <Button
          bgColor="bg-main-bg-pink"
          text="Login"
          size="md"
          borderRadius="0px"
          type="submit"
        />
        {/* </div> */}
      </form>
      <Button
        bgColor="transparent"
        text="Register a New Band"
        size="md"
        borderRadius="0px"
        color="white"
        border="2px solid white"
        onClick={routeChange}
      />
    </div>
  )
}

export default Login
