import React from 'react'

import Login from '../components/Login'
import { useAuth } from '../utils/auth'
import { useNavigate } from 'react-router-dom'
// import Register from "../components/Register";

const Authentication = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  // console.log(auth.loggedIn);

  if (auth.loggedIn) {
    console.log('Logged in', auth.loggedIn)
    return navigate('/')
  }

  return (
    <div className="flex flex-col items-center h-auto  m-4 mt-24">
      <h2 className="text-6xl mb-12 text-pink">BndMgr.com</h2>

      <Login />
      {/* <Register /> */}
    </div>
  )
}

export default Authentication
