import React, { useState } from 'react'
import Calendars from '../components/Calendar/Calendars'
import Availability from '../components/Availability'

export const CalendarPage = () => {
  const tabs = [
    { id: 1, title: 'Booking', content: <Calendars /> },
    { id: 2, title: 'Availability', content: <Availability /> },
  ]

  const [activeTab, setActiveTab] = useState(tabs[0])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  return (
    <div className="tab-container p-5">
      <div className="tab-header flex gap-5 cursor-pointer mb-6">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${
              tab.id === activeTab.id
                ? 'active text-pink underline underline-offset-8'
                : ''
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">{activeTab.content}</div>
    </div>
  )
}
