import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createNewBand, deleteBand, fetchBand } from '../../api/Bands/bands'
import { toast } from 'react-toastify'

export const useFetchBand = () => {
  return useQuery(['getBands'], () => fetchBand(), {
    refetchInterval: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    staleTime: 60000,
  })
}

export const useCreateNewBand = () => {
  return useQuery(['createNewBands'], () => createNewBand(), {
    refetchInterval: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    staleTime: 60000,
  })
}

export const useDeleteBand = (onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteBand'],
    (value) => {
      return deleteBand(value.id)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Band Deleted Successfully')
        queryClient.invalidateQueries(['getBands'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: () => {
        toast.error('Failed to delete Band')
      },
    },
  )
}
