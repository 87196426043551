import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { forgotPassword, login, register } from '../../api/auth/auth'
import { setUser } from '../../utils/cookieHelper'
import { useAuth } from '../../utils/auth'

export const useLogin = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  return useMutation(
    ['login'],
    ({ email, password }) => login(email, password),
    {
      onSuccess: (data) => {
        setUser(data)
        auth.setLoggedIn(true)
        navigate('/')
      },
    },
  )
}

export const useRegister = () => {
  const navigate = useNavigate()
  return useMutation(
    ['register'],
    ({ email, password, nickname, bandName }) =>
      register(email, password, nickname, bandName),
    {
      onSuccess: (data, variables, context) => {
        navigate('/login')
        console.log('Register', data, variables, context)
      },
    },
  )
}

export const useForgotPass = () => {
  const navigate = useNavigate()

  return useMutation(['forgot-pass'], ({ email }) => forgotPassword(email), {
    onSuccess: () => {
      navigate('/')
    },
  })
}
