import React, { useState } from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import SortableItem from './DragAndDrop/SortableItem'
import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

const SongList = () => {
  const [songs, setSongs] = useState([
    { id: 1, title: 'Bohemian Rhapsody' },
    { id: 2, title: 'Uptown Funk' },
    { id: 3, title: 'Imagine' },
    { id: 4, title: 'Billie Jean' },
    { id: 5, title: 'Hotel California' },
    { id: 6, title: 'Rolling in the Deep' },
    { id: 7, title: 'Hey Jude' },
    { id: 8, title: 'Smells Like Teen Spirit' },
    { id: 9, title: "Sweet Child o' Mine" },
  ])
  const navigate = useNavigate()

  const handleAddSong = () => {
    navigate('/add-song')
  }

  const handleOnDragEnd = (event) => {
    console.log('🚀 ~ file: SongList.jsx:31 ~ handleOnDragEnd ~ event:', event)
    const { active, over } = event
    if (!over) {
      return
    }
    if (active.id !== over.id) {
      setSongs((items) => {
        const activeIndex = items.findIndex((item) => item.id === active.id)
        const overIndex = items.findIndex((item) => item.id === over.id)
        return arrayMove(items, activeIndex, overIndex)
      })
    }
  }

  return (
    <div className="flex flex-col bg-main-bg-dark p-2 h-full overflow-auto">
      <p className=" text-18 font-semibold text-white p-2">Song List</p>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleOnDragEnd}
      >
        <ul className="flex flex-col flex-grow list-none gap-1 overflow-auto">
          <SortableContext items={songs} strategy={verticalListSortingStrategy}>
            {songs.map(({ id, title }) => (
              <SortableItem key={id} id={id} title={title} />
            ))}
          </SortableContext>
        </ul>
      </DndContext>
      <Button
        bgColor="bg-main-bg-pink"
        text="Add Song"
        size="md"
        borderRadius="0px"
        classes={'w-full mt-5'}
        onClick={handleAddSong}
      />
    </div>
  )
}

export default SongList
