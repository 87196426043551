import * as Yup from 'yup'

const loginSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required('Required').email('Email is invalid'),
    password: Yup.string().required('Required').min(8, 'Password too short'),
  })
}

const registerSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required('Required').email('Email is invalid'),
    nickname: Yup.string().required('Required').min(4, 'Nickname too short'),
    bandName: Yup.string().required('Required').min(4, 'Band name too short'),
    password: Yup.string().required('Required').min(8, 'Password too short'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password Confirmation is required'),
  })
}

const forgotPassSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required('Required').email('Email is invalid'),
  })
}

export { loginSchema, registerSchema, forgotPassSchema }
