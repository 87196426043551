import Cookies from 'js-cookie'

const COOKIE_NAME = 'npuinf'

export const setUser = (data) => {
  Cookies.set(COOKIE_NAME, JSON.stringify(data), { expires: 3, secure: true })
  console.log('Set USer', Cookies.get(COOKIE_NAME))
}

export const getUser = () => {
  const data = Cookies.get(COOKIE_NAME)

  //   console.log(cookie_data.data);
  if (data) {
    const cookie_data = JSON.parse(data)
    return cookie_data.data
  }

  return {
    accessToken: '',
    user: '',
  }
}

export const removeUser = () => {
  Cookies.remove(COOKIE_NAME)
}
