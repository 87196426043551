import React from 'react'
import Input from './Input'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import { useRegisterForm } from '../hooks/auth/components/useRegister'
// import { AiFillQuestionCircle } from "react-icons/ai";

const Register = () => {
  const { formik } = useRegisterForm()
  const navigate = useNavigate()
  const routeChange = () => {
    // console.log("Clcked");
    // let path = `/login`;
    navigate(`/login`)
  }

  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return (
    <div className="flex flex-col text-left w-96 px-4 pt-6 pb-4 bg-main-bg-dark text-white">
      <p className="text-3xl mb-4">Register</p>
      <form
        onSubmit={(e) => handleForm(e)}
        className="flex flex-col text-left w-full gap-4 mb-4"
      >
        <div className="relative">
          <Input
            value={formik.values.bandName}
            placeholder="Band Name"
            name="bandName"
            label="Band Name"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bandName && formik.errors.bandName}
            classes={formik.errors.bandName ? 'border border-pink' : null}
          />
          {formik.errors.bandName ? (
            <div className="absolute right-0 top-auto text-pink ">
              {formik.errors.bandName}
            </div>
          ) : null}
        </div>
        <div className="relative">
          <Input
            value={formik.values.nickname}
            placeholder="Your Nick Name"
            name="nickname"
            label="Nick Name"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nickname && formik.errors.nickname}
            classes={formik.errors.nickname ? 'border border-pink' : null}
          />
          {formik.errors.nickname ? (
            <div className="absolute right-0 top-auto text-pink ">
              {formik.errors.nickname}
            </div>
          ) : null}
        </div>
        <div className="relative">
          <Input
            value={formik.values.email}
            placeholder="Enter Email"
            name="email"
            label="Email"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            classes={formik.errors.email ? 'border border-pink' : null}
          />
          {formik.errors.email ? (
            <div className="absolute right-0 top-auto text-pink ">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className="relative">
          <Input
            value={formik.values.password}
            placeholder="Enter Password"
            name="password"
            label="Password"
            type="password"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            classes={formik.errors.password ? 'border border-pink' : null}
          />
          {formik.errors.password ? (
            <div className="absolute right-0 top-auto text-pink ">
              {formik.errors.password}
            </div>
          ) : null}
        </div>
        <div className="relative">
          <Input
            value={formik.values.confirm_password}
            placeholder="Enter Password"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
            classes={
              formik.errors.confirm_password ? 'border border-pink' : null
            }
          />
          {formik.errors.confirmPassword ? (
            <div className="absolute right-0 top-auto text-pink ">
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </div>

        <Button
          bgColor="bg-main-bg-pink"
          text="Register"
          size="md"
          borderRadius="0px"
          type="submit"
          classes={'mt-4'}
        />
      </form>
      <Button
        bgColor="transparent"
        text="Go back to Login"
        size="md"
        borderRadius="0px"
        color="white"
        border="2px solid white"
        onClick={routeChange}
      />
    </div>
  )
}

export default Register
