import React from 'react'
import {
  AiOutlineArrowLeft,
  AiOutlineSearch,
  AiFillDelete,
  AiOutlineDownload,
  AiOutlineEdit,
} from 'react-icons/ai'
import { RiMusicFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import SongList from '../../components/SongList'

const songs = {
  songs: [
    'Shape of You',
    'Bohemian Rhapsody',
    'Uptown Funk',
    'Imagine',
    'Billie Jean',
    'Hotel California',
    'Rolling in the Deep',
    'Hey Jude',
    'Smells Like Teen Spirit',
    "Sweet Child o' Mine",
  ],
}

export const SongListPage = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col p-3">
      <div className="flex justify-between">
        <div className="flex items-center gap-2  mb-4">
          <AiOutlineArrowLeft onClick={handleGoBack} />
          <p className=" text-16 font-semibold">Manage Set List</p>
        </div>
        <div className="flex gap-2 text-20">
          <AiOutlineSearch /> <AiOutlineDownload /> <AiOutlineEdit />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className=" bg-main-bg-dark p-2">
          <p className=" text-18 font-semibold text-white p-2">Set List Name</p>
          <ul className="flex flex-col list-none gap-1 overflow-auto h-96">
            {songs.songs.map((title) => (
              <li
                key={title}
                className="flex items-center justify-between p-2  text-white"
              >
                <div className="flex gap-2 items-center ">
                  <RiMusicFill className="text-pink" />
                  <p>{title}</p>
                </div>
                <div>
                  <AiFillDelete />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="h-60 overflow-auto">
          <SongList />
        </div>
      </div>
    </div>
  )
}
