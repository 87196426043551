import { useMutation } from '@tanstack/react-query'

import { toast } from 'react-toastify'
import { updateUserNickname } from '../../api/User/users'

export const useUpdateUsers = (onSuccess) => {
  return useMutation(
    ['updateUsers'],
    (value) => updateUserNickname(value),
    {
      onSuccess: (data, variables, context) => {
        console.log('🚀 ~ file: useUsers.js:12 ~ useUpdateUsers ~ data:', data)
        toast.success('Updated Nickname Successfully')

        onSuccess && onSuccess(data, variables, context)
      },
      onError: () => {
        toast.error('Error updating nickname')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}
