import { useFormik } from 'formik'

import { useUpdateUsers } from './useUsers'

import { usersSchema } from './usersSchema'

export const useUserUpdateForm = (value) => {
  //   const initialValues = {
  //     nickname: value,
  //   };
  const { mutate, isLoading } = useUpdateUsers({})

  const formik = useFormik({
    initialValues: {
      nickname: value,
    },
    validationSchema: usersSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    try {
      await mutate(values)
    } catch (error) {
      console.log('Error', error)
    }
  }
  const handleKeyDown = (event) => {
    // Trigger validation on keydown event
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }
  return {
    formik,
    isLoading,
    handleKeyDown,
  }
}
