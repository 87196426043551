import React, { useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

const Availability = () => {
  // eslint-disable-next-line react/jsx-key
  const values = ['', <AiOutlineCheck />, <AiOutlineClose />]
  const [currentIndex, setCurrentIndex] = useState(0)

  // const [divStates, setDivStates] = useState(Array(31).fill(false))

  const handleClick = (event) => {
    console.log(event.target)
    setCurrentIndex((prevIndex) => (prevIndex + 1) % values.length)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xl flex justify-center ">
        <div className="text-xl font-bold">August 2023</div>
      </div>
      <div className="overflow-x-auto">
        <table
          className="availability-table bg-gray-500"
          align="center"
          cellSpacing="10"
          cellPadding="10"
        >
          <caption align="top"></caption>
          <thead>
            <tr className="">
              <th></th>
              <th className="bg-cyan-300 text-black">1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th className="bg-cyan-300 text-black">7</th>
              <th className="bg-cyan-300 text-black">8</th>
              <th>9</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>13</th>
              <th className="bg-cyan-300 text-black">14</th>
              <th className="bg-cyan-300 text-black">15</th>
              <th>16</th>
              <th>17</th>
              <th>18</th>
              <th>19</th>
              <th>20</th>
              <th className="bg-cyan-300 text-black">21</th>
              <th className="bg-cyan-300 text-black">22</th>
              <th>23</th>
              <th>24</th>
              <th>25</th>
              <th>26</th>
              <th>27</th>
              <th className="bg-cyan-300 text-black">28</th>
              <th className="bg-cyan-300 text-black">29</th>
              <th>30</th>
              <th>31</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-white">Sannish</td>

              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
            </tr>
            <tr className="opacity-60">
              <td className="bg-white">Mukti</td>

              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-xl flex justify-center">
        <div className="text-xl font-bold">September 2023</div>
      </div>
      <div className="overflow-x-auto">
        <table
          className="availability-table justify-self-start bg-gray-500"
          align="center"
          cellSpacing="10"
          cellPadding="10"
        >
          <caption align="top"></caption>
          <thead>
            <tr className="">
              <th></th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th className="bg-cyan-300 text-black">4</th>
              <th className="bg-cyan-300 text-black">5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
              <th className="bg-cyan-300 text-black">11</th>
              <th className="bg-cyan-300 text-black">12</th>
              <th>13</th>
              <th>14</th>
              <th>15</th>
              <th>16</th>
              <th>17</th>
              <th className="bg-cyan-300 text-black">18</th>
              <th className="bg-cyan-300 text-black">19</th>
              <th>20</th>
              <th>21</th>
              <th>22</th>
              <th>23</th>
              <th>24</th>
              <th className="bg-cyan-300 text-black">25</th>
              <th className="bg-cyan-300 text-black">26</th>
              <th>27</th>
              <th>28</th>
              <th>29</th>
              <th>30</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-white">Sannish</td>
              <td onClick={handleClick} className="bg-white">
                {values[currentIndex]}
              </td>
              <td onClick={handleClick} className="bg-white">
                {values[currentIndex]}
              </td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
            </tr>
            <tr className="opacity-60">
              <td className="bg-white">Mukti</td>
              <td onClick={handleClick} className="bg-white">
                {values[currentIndex]}
              </td>
              <td onClick={handleClick} className="bg-white">
                {values[currentIndex]}
              </td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-cyan-300"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
              <td className="bg-white"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Availability
