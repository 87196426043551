import React, { useState, useEffect } from 'react'
import './Calendars.css'

export const createCalendar = (year, month, currentDate) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const firstDayOfWeek = new Date(year, month, 1).getDay()

  const calendar = []

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // const navigate = useNavigate();

  // const addPractice = () => {
  //   navigate("/add-practice");
  // };

  for (let day of weekdays) {
    calendar.push(
      <div key={day} className="weekday">
        {day}
      </div>,
    )
  }

  for (let i = 0; i < firstDayOfWeek; i++) {
    calendar.push(<div key={`empty-${i}`} className="empty-day" />)
  }

  //   for (let day = 1; day <= daysInMonth; day++) {
  //     calendar.push(
  //       <div key={day} className="day p-3">
  //         {day}
  //       </div>
  //     );
  //   }

  for (let day = 1; day <= daysInMonth; day++) {
    const isToday =
      year === currentDate.getFullYear() &&
      month === currentDate.getMonth() &&
      day === currentDate.getDate()

    calendar.push(
      <div
        key={day}
        className={`day w-10 h-10 flex items-center justify-center ${
          isToday ? 'today bg-main-bg-green rounded-full' : ''
        }`}
        // onClick={(e) => addPractice(e)}
      >
        {day}
      </div>,
    )
  }

  return calendar
}

function Calendars() {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    // Fetch or set your initial date as needed
    setCurrentDate(new Date())
  }, [])

  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const calendarMonths = []

  for (let i = 0; i < 6; i++) {
    const year = currentYear
    const month = currentMonth + i
    calendarMonths.push(
      <div
        key={`calendar-${year}-${month}`}
        className="calendar-container text-center bg-main-bg-dark"
      >
        <div className="calendar-header text-white bg-main-bg-pink p-4">
          {new Date(year, month).toLocaleString('default', { month: 'long' })}{' '}
          {year}
        </div>
        <div className="calendar-grid p-3 text-white">
          {createCalendar(year, month, currentDate)}
        </div>
      </div>,
    )
  }

  return (
    <div className="flex flex-wrap">
      {/* <h1>Simple Calendar</h1> */}
      <div className="calendar-wrapper flex flex-wrap gap-4 justify-center">
        {calendarMonths}
      </div>
    </div>
  )
}

export default Calendars
