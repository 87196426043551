import { axiosInstance } from '../axiosInterceptor'

export const login = async (email, password) => {
  const response = await axiosInstance.post('/auth/login', {
    email,
    password,
  })
  return response
}

export const register = async (email, password, nickname, bandName) => {
  const response = await axiosInstance.post('/auth/register', {
    email,
    password,
    nickname,
    bandName,
  })
  return response
}

export const forgotPassword = async (email) => {
  const response = await axiosInstance.post('/auth/password/forgot', {
    email,
  })
  return response
}
