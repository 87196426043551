import React from 'react'
import PropTypes from 'prop-types'

const Input = ({
  label,
  value,
  placeholder,
  onChange,
  type,
  name,
  classes,
  disabled,
}) => {
  return (
    <div className="flex flex-col w-full relative">
      <label className="pb-1 text-14">{label}</label>
      <input
        value={value}
        placeholder={placeholder}
        name={name}
        type={type}
        onChange={onChange}
        className={`px-4 py-3 input-field text-gray-400 rounded-none ${classes}`}
        disabled={disabled}
      />
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Input
