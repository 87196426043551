import { useState, createContext, useContext, useEffect } from 'react'
import { getUser } from './cookieHelper'

const AuthContext = createContext()

const initialState = {
  loggedIn: false,
}

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  //   const { data } = getUser();

  const [loggedIn, setLoggedIn] = useState(initialState.loggedIn)
  const [accessToken, setAccessToken] = useState('')
  const [user, setUser] = useState({})

  useEffect(() => {
    const { accessToken, user } = getUser()
    // const { accessToken, user } = data;

    if (accessToken && user) {
      setAccessToken(accessToken)
      setLoggedIn(true)
      setUser(user)
    }
  }, [])

  // console.log("Logged in", loggedIn);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AuthContext.Provider value={{ accessToken, user, loggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
