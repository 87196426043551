import React from 'react'
import {
  AiOutlineBars,
  AiOutlineCalendar,
  AiOutlineMessage,
  AiOutlineUser,
} from 'react-icons/ai'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className="flex flex-row mb-2 gap-2 justify-between sticky top-0 h-12">
      <div className="flex items-center justify-center w-12">
        <h6 className="text-2xl text-pink">BM</h6>
      </div>
      <div className="flex flex-row">
        <NavLink
          to={`/chat`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineMessage />
          <div className="title">Chat</div>
        </NavLink>
        <NavLink
          to={`/calendar`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineCalendar />
          <div className="title">Calendar</div>
        </NavLink>
        <NavLink
          to={`/set-lists`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineBars />
          <div className="title">Set Lists</div>
        </NavLink>
        <NavLink
          to={`/profile`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineUser />
          <div className="title">Profile</div>
        </NavLink>
      </div>
    </nav>
  )
}

export default Navbar
