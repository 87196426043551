import { axiosInstance } from '../axiosInterceptor'

export const createNewBand = async () => {
  const data = await axiosInstance.post(`bands`)
  return data
}

export const fetchBand = async () => {
  const data = await axiosInstance.get(`bands`)
  return data
}

export const deleteBand = async ({ queryKey }) => {
  const bandId = queryKey[1]
  const data = await axiosInstance.delete(`bands/${bandId}`)
  return data
}
