import React, { useState } from 'react'
import { useAuth } from '../utils/auth'
import { useNavigate } from 'react-router-dom'
import { removeUser } from '../utils/cookieHelper'
import { AiOutlineEdit, AiOutlineLogout } from 'react-icons/ai'
import Input from '../components/Input'
import Button from '../components/Button'
import { useFetchBand } from '../hooks/bands/useBands'
import { RiAddBoxLine, RiDeleteBin7Line } from 'react-icons/ri'
import { useUserUpdateForm } from '../hooks/users/useUserUpdateForm'

export const Profile = () => {
  const auth = useAuth()

  const { formik: updateFormik } = useUserUpdateForm(auth.user.nickname)

  // const isError = formik.touched[name] && formik.errors[name];

  const { data, isLoading, isError: isFetchError } = useFetchBand()

  const [editDetails, setEditDetails] = useState(false)

  const navigate = useNavigate()

  const bandData = data

  const logOut = (e) => {
    e.preventDefault()
    removeUser()
    navigate('/login')
    auth.setLoggedIn(false)
    // window.location.reload();
  }

  const handleEditDetails = () => {
    setEditDetails((prevEditDetails) => !prevEditDetails)
    updateFormik.resetForm()
  }

  // const handleForm = (e) => {
  //   e.preventDefault();
  //   formik.handleSubmit();
  // };
  const handleEditForm = (e) => {
    e.preventDefault()
    updateFormik.handleSubmit()
  }
  const handleAddBand = (e) => {
    e.preventDefault()
    console.log('🚀 ~ file: Profile.jsx:52 ~ handleAddBand ~ e:', e)

    // formik.handleSubmit();
  }
  const handleDeleteBand = (e) => {
    e.preventDefault()
    console.log('🚀 ~ file: Profile.jsx:58 ~ handleDeleteBand ~ e:', e)
    //  formik.handleSubmit();
  }

  return (
    <div className="flex flex-col p-3">
      <div className="flex flex-row justify-between items-center">
        <div>
          Welcome <span className="font-bold">{auth.user.nickname}</span>,
        </div>
        <button onClick={(e) => logOut(e)} className="text-2xl">
          <AiOutlineLogout />
        </button>
      </div>
      <div className="mt-4 bg-main-bg-dark p-2  w-full lg:w-1/3 m-auto">
        <div className="flex flex-row justify-between items-center ">
          <p className=" text-18 font-semibold text-white p-2">
            Profile Details
          </p>
          <div
            className="text-20 text-white p-2 cursor-pointer"
            onClick={handleEditDetails}
          >
            <AiOutlineEdit />
          </div>
        </div>
        {editDetails ? (
          <form
            onSubmit={(e) => handleEditForm(e)}
            className="flex flex-col text-left w-full gap-4 mb-4 p-2 text-white"
          >
            <div className="relative">
              <Input
                value={auth.user.email}
                name="email"
                label="Email"
                disabled
                classes={`mb-4`}
              />
              <Input
                value={updateFormik.values.nickname}
                name="nickname"
                label="Nickname"
                placeholder="Enter nickname"
                formik={updateFormik}
                type="text"
                onChange={updateFormik.handleChange}
                onBlur={updateFormik.handleBlur}
                error={
                  updateFormik.touched.nickname && updateFormik.errors.nickname
                }
                classes={`${
                  updateFormik.errors.nickname ? 'border border-pink' : null
                }`}
              />
              {updateFormik.errors.nickname ? (
                <div className="absolute right-0 top-auto text-pink ">
                  {updateFormik.errors.nickname}
                </div>
              ) : null}
            </div>
            <Button
              bgColor="bg-main-bg-pink"
              text="Update Nickname"
              size="md"
              borderRadius="0px"
              classes={'w-full mt-4'}
              type="submit"
            />
          </form>
        ) : (
          <form
            // onSubmit={(e) => handleForm(e)}
            className="flex flex-col text-left w-full gap-4 mb-4 p-2 text-white"
          >
            <div className="relative">
              <Input
                value={auth.user.email}
                name="email"
                label="Email"
                disabled
                classes={`mb-4`}
              />
              <Input
                value={auth.user.nickname}
                name="text"
                label="Nickname"
                disabled
              />
            </div>
          </form>
        )}
      </div>
      <div className="mt-4 bg-main-bg-dark p-2  w-full lg:w-1/3 m-auto">
        <div className="flex flex-row justify-between items-center ">
          <p className=" text-18 font-semibold text-white p-2">Band Details</p>
          <button
            onClick={handleAddBand}
            className="flex flex-row justify-between items-center text-white px-3 gap-2 hover:text-pink text-2xl"
          >
            <RiAddBoxLine />
          </button>
        </div>

        <form
          // onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4 p-2 text-white"
        >
          <div className="relative">
            {isLoading ? (
              <div>
                <Input
                  value="..."
                  name="text"
                  label="Band Name"
                  classes={`mb-4`}
                  disabled
                />
              </div>
            ) : isFetchError ? (
              <div className="my-4">Error Loading Data</div>
            ) : (
              <div>
                {bandData?.data.map((value) => (
                  <div
                    className="flex flex-row items-center justify-between gap-3"
                    key={value.id}
                  >
                    <Input
                      value={value.name}
                      name="text"
                      label="Band Name"
                      classes={`mb-4 w-full`}
                      disabled
                    />
                    <div
                      className="flex justify-center items-center w-4 hover:text-pink cursor-pointer"
                      onClick={handleDeleteBand}
                    >
                      <RiDeleteBin7Line />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <Button
              bgColor="bg-main-bg-pink"
              text="Register New Band"
              size="md"
              borderRadius="0px"
              classes={'w-full'}
              // type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  )
}
