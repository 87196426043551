import React, { useState, useEffect } from 'react'
import {
  AiFillDelete,
  AiFillFileText,
  AiOutlineAppstore,
  AiOutlineDatabase,
  AiOutlineFileAdd,
  AiOutlineSearch,
} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import SongList from '../../components/SongList'

import {
  RiAddLine,
  RiArchiveFill,
  RiFileDownloadLine,
  RiMusicFill,
} from 'react-icons/ri'
const playlists = [
  {
    playlistId: 1,
    playlistName: 'Rock Classics',
    songs: [
      { id: 1, title: 'Bohemian Rhapsody' },
      { id: 8, title: 'Smells Like Teen Spirit' },
    ],
  },
  {
    playlistId: 2,
    playlistName: 'Pop Hits',
    songs: [
      { id: 2, title: 'Uptown Funk' },
      { id: 4, title: 'Billie Jean' },
    ],
  },
  {
    playlistId: 3,
    playlistName: 'Classic Rock',
    songs: [
      { id: 5, title: 'Hotel California' },
      { id: 9, title: "Sweet Child o' Mine" },
    ],
  },
  {
    playlistId: 4,
    playlistName: 'Piano Melodies',
    songs: [
      { id: 3, title: 'Imagine' },
      { id: 7, title: 'Hey Jude' },
    ],
  },
  {
    playlistId: 5,
    playlistName: 'Soulful Ballads',
    songs: [
      { id: 6, title: 'Rolling in the Deep' },
      { id: 9, title: "Sweet Child o' Mine" },
    ],
  },
]

export const SetLists = () => {
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(false)
  const handleNewSetList = () => {
    navigate('/add-setlist')
  }

  const handleSetlist = () => {
    navigate('songlist')
  }

  const updateIsMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile)

    updateIsMobile()

    return () => {
      window.removeEventListener('resize', updateIsMobile)
    }
  }, [])

  return (
    <div className="flex flex-col p-3 lg:px-0">
      <div className="flex justify-between">
        <div className=" text-16 font-semibold">Manage Set List</div>
        <div className="flex gap-2 text-20">
          <AiOutlineFileAdd onClick={handleNewSetList} />
          <AiOutlineSearch /> <AiOutlineAppstore /> <AiOutlineDatabase />
        </div>
      </div>
      <div>
        {isMobile ? (
          <div className="my-4">
            <ul className="flex flex-col list-none p-0 gap-2 overflow-auto">
              {playlists.map((playlist) => (
                <li
                  key={playlist.playlistId}
                  className="flex items-center justify-between p-2 bg-main-bg-dark text-white"
                  onClick={handleSetlist}
                >
                  <div className="flex gap-2 items-center">
                    <AiFillFileText />
                    <p>{playlist.playlistName}</p>
                  </div>
                  <div>
                    <AiFillDelete />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <div className="flex gap-4">
              <div className="my-4">
                <ul className="flex flex-wrap list-none gap-2">
                  {playlists.map(({ playlistId, playlistName, songs }) => (
                    <li
                      key={playlistId}
                      className="setlist flex flex-grow items-start justify-between p-2 bg-main-bg-dark text-white w-1/5 h-60 "
                    >
                      <div className="flex flex-col w-full gap-3">
                        <div className="hdr flex flex-row justify-between w-full items-center">
                          <div className="flex gap-2 items-center ">
                            <p className="font-bold">{playlistName}</p>
                            <RiFileDownloadLine />
                          </div>
                          <div>
                            <RiArchiveFill />
                          </div>
                        </div>
                        <div className="h-[320px] flex flex-grow flex-col overflow-auto">
                          {songs.map(({ id, title }) => (
                            <div className="flex items-center gap-2 " key={id}>
                              <RiMusicFill className="text-pink" />
                              <p> {title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  ))}
                  <li
                    className="setlist flex flex-col flex-grow items-center justify-center p-2 border-dashed border-1 border-gray-600 text-gray-600 w-1/5 h-60 max-w-[290px] cursor-pointer hover:shadow-2xl"
                    onClick={handleNewSetList}
                  >
                    <RiAddLine className="text-3xl" /> Add New Set List
                  </li>
                </ul>
              </div>
              <div className="songlist my-4 ">
                <SongList />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
