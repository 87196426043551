import React from 'react'
import Input from '../components/Input'
import Button from '../components/Button'
import { useLoginForm } from '../hooks/auth/components/useLogin'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const AddGig = () => {
  const { formik } = useLoginForm()

  // const navigate = useNavigate();
  const routeChange = () => {
    //   navigate(`/register`);
  }
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return (
    <div className="flex flex-col p-3">
      <div className="flex items-center gap-2  mb-4">
        <AiOutlineArrowLeft onClick={handleGoBack} />
        <p className=" text-16 font-semibold">Add Gig</p>
      </div>
      <div className="bg-main-bg-dark p-5  w-full lg:w-1/3 m-auto">
        <form
          onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4 text-white"
        >
          <Input
            value={formik.values.location}
            placeholder="Location"
            name="location"
            label="Location"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.location && formik.errors.location}
            classes={formik.errors.location ? 'border border-pink' : null}
          />
          <div className="flex flex-row w-full">
            <Input
              value={formik.values.time}
              placeholder="Time"
              name="time"
              label="Time"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.time && formik.errors.time}
              classes={`w-2/3 ${
                formik.errors.time ? 'border border-pink' : null
              }`}
            />
            <Input
              value={formik.values.setlist}
              placeholder="Enter setlist"
              name="setlist"
              label="Setlist"
              type="text"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.setlist && formik.errors.setlist}
              classes={` flex-grow ${
                formik.errors.time ? 'border border-pink' : null
              }`}
            />
          </div>
          <Input
            value={formik.values.notes}
            placeholder="Enter notes"
            name="notes"
            label="Notes"
            type="text"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.notes && formik.errors.notes}
            classes={formik.errors.notes ? 'border border-pink' : null}
          />

          <Button
            bgColor="bg-main-bg-pink"
            text="Save"
            size="md"
            borderRadius="0px"
            type="submit"
          />
        </form>
        <Button
          bgColor="transparent"
          text="Delete"
          size="md"
          borderRadius="0px"
          color="white"
          border="2px solid white"
          onClick={routeChange}
          classes={`w-full`}
        />
      </div>
    </div>
  )
}

export default AddGig
